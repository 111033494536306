import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Email } from '../models/Email';
import { ApiService } from '../../services/api.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent implements OnInit {
  email: Email;
  showAllErrors = false;

  constructor(
    private _apiService: ApiService,
    private _authService: AuthService,
    private _snackBar: MatSnackBar) { 
    this.email = history.state.email ?? this.getEmptyEmail();
  }

  ngOnInit(): void {
  }

  update(isValid){
    if(!isValid) {
      this.showAllErrors = true;
      return;
    }

    if(this.email.id == 0){
      this._apiService.addEmail(this.email)
        .toPromise()
        .then(x => {
          this.openSnackBar(`Successfully added the record.`);
        })
        .catch(exc => this.openSnackBar(`Error: ${exc.error}`));
    } else{
      this._apiService.updateEmail(this.email, this._authService.getToken)
        .toPromise()
        .then(x => {
          this.openSnackBar(`Successfully updated the record.`);
        })
        .catch(exc => this.openSnackBar(`Error: ${exc.error}`));
    }
  }

  getEmptyEmail(){
    let email: Email = {
      deleted: false,
      firstName: '',
      lastName: '',
      id: 0,
      emailAddress: ''
    }

    return email;
  }
  
  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 2000,
    });
  }
}
