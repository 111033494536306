<div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="space-between center">
    <mat-form-field class="w100">
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. @gmail.com" #input>
    </mat-form-field>
  </div>
  
  <div class="mat-elevation-z8">
    <table class="w100" mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox 
            color="primary"
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox 
            color="primary"
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"            
            [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </td>
      </ng-container>
    
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> Id </th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>
    
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef> First Name </th>
        <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
      </ng-container>
    
      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef> Last Name </th>
        <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
      </ng-container>
  
      <ng-container matColumnDef="emailAddress">
          <th mat-header-cell *matHeaderCellDef> Email </th>
          <td mat-cell *matCellDef="let element"> {{element.emailAddress}} </td>
        </ng-container>
    
      <ng-container matColumnDef="deleted">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.deleted"><button mat-stroked-button color="primary" (click)="activateEmail(element)">Activate</button></div>
        </td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="isSmallScreen ? displayedColumnsSmallScreen : displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: isSmallScreen ? displayedColumnsSmallScreen : displayedColumns;"
          (click)="selection.toggle(row)">
      </tr>
  
      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr>
    </table>
    <mat-paginator pageSize='50' [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>