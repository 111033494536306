import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Email } from '../../models/Email';
import { ApiService } from '../../../services/api.service';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class InactiveEmailsResolverService implements Resolve<Email[]> {

  constructor(private _apiService: ApiService, private _authService: AuthService) { }

  resolve(route: ActivatedRouteSnapshot): Observable<Email[]> {
    return this._apiService.getInactiveEmails(this._authService.getToken);
  }
}