<form #f="ngForm">
  <div fxLayout="column" fxLayoutGap="15px" fxLayoutAlign="start">
    <mat-form-field>
      <mat-label>First Name</mat-label>
      <input #firstName="ngModel" [(ngModel)]="email.firstName" name="firstName" matInput placeholder="ex. John">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Last Name</mat-label>
      <input #lastName="ngModel" [(ngModel)]="email.lastName" name="lastName" matInput placeholder="ex. Schmidt">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Email Address</mat-label>
      <input #emailAddress="ngModel" [(ngModel)]="email.emailAddress" name="emailAddress" required matInput placeholder="ex. john.scmidt@freevoiceoverleads.com">
    </mat-form-field>
    <div><button mat-raised-button color="primary" type="submit" (click)="update(f.valid)">{{email.id == 0 ? 'Add New' : 'Update Existing'}}</button></div>
  </div>
</form>
