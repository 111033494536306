import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.isAuthenticated()
      .then(x => {
        console.log('AuthGuard-isAuthenticated-result', x)
        if(!x) 
          this.router.navigate(['/login'], { queryParams: { redirect: state.url }, replaceUrl: true });
        return x;
      })
      .catch(err => {
        console.log('AuthGuard-isAuthenticated-error', err);
        this.router.navigate(['/login'], { queryParams: { redirect: state.url }, replaceUrl: true });
        return false;
      })   
  }

}