<mat-toolbar color="primary">
    <button mat-icon-button class="example-icon" (click)="snav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <span>Voice Over Leads</span>
    <span class="example-spacer"></span>
    <button mat-icon-button class="example-icon favorite-icon" (click)="logout()">
      <mat-icon>logout</mat-icon>
    </button>
</mat-toolbar>

<mat-sidenav-container>
    <mat-sidenav #snav mode="over">
      <mat-nav-list (click)="snav.toggle()">
        <a mat-list-item routerLink="/admin/active-emails">Active Emails</a>
        <a mat-list-item routerLink="/admin/inactive-emails">Inactive Emails</a>
        <a mat-list-item routerLink="/admin/edit">Add Emails</a>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="container">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>