<div class="wrapper"><!--wrapper started-->
	<div class="abovebanner"><!-- above banner started -->
			<div class="header"><!-- header started-->
				<h1 id="logo">Free <span>Voiceover Leads</span></h1>
				<p class="line1">Voice talent job leads sent straight to your inbox</p>
			</div><!-- header ended-->
						
			<div class="content"><!-- content started-->
					<p>Click on the button below to register, and we'll send you voice talent job leads. You deal with clients directly. No commissions, no catch.</p>
					
					<button class="btn btn-success" (click)="openDialog()">Register</button>
			
			</div><!-- content ended -->
	</div><!-- above banner ended -->
		<div class="row belowbanner">
			<div class="col-md-6 col-sm-12 email">
				<h2>email us</h2>
				<p><a href="mailto:trish@voiceoverbytrish.com">trish@voiceoverbytrish.com</a></p>
			</div>
			<div class="col-md-6 col-sm-12 social">
				<h2>social</h2>
					<a href="https://www.facebook.com/VoiceoversByTrish" class="socicon-facebook"><i class="fa fa-facebook-square fa-2x" aria-hidden="true"></i></a>
					<a href="https://twitter.com/TrishsVoice" class="socicon-twitter"><i class="fa fa-twitter-square fa-2x" aria-hidden="true"></i></a>
					<a href="https://www.youtube.com/user/voiceoversbytrish" class="socicon-youtube"><i class="fa fa-youtube-square fa-2x" aria-hidden="true"></i></a>
					<a href="https://www.instagram.com/voiceoversbytrish/" class="socicon-instagram"><i class="fa fa-instagram fa-2x" aria-hidden="true"></i></a>
			</div>		
		</div>
		<!-- <div class="belowbanner">
			<div class="email">
				<h2>email us</h2>
				<p><a href="mailto:trish@voiceoverbytrish.com">trish@voiceoverbytrish.com</a></p>
			</div>
			<div class="social">
				<h2>social</h2>
					<a href="https://www.facebook.com/VoiceoversByTrish" class="socicon-facebook"><i class="fa fa-facebook-square fa-2x" aria-hidden="true"></i></a>
					<a href="https://twitter.com/TrishsVoice" class="socicon-twitter"><i class="fa fa-twitter-square fa-2x" aria-hidden="true"></i></a>
					<a href="https://www.youtube.com/user/voiceoversbytrish" class="socicon-youtube"><i class="fa fa-youtube-square fa-2x" aria-hidden="true"></i></a>
					<a href="https://www.instagram.com/voiceoversbytrish/" class="socicon-instagram"><i class="fa fa-instagram fa-2x" aria-hidden="true"></i></a>
			</div>			
			<div class="call">
				<h2>call us</h2>
				<p>888-888-8888</p>
			</div>			
		</div> -->
</div>