import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private httpOptions;
  private _dataChangeSub = new Subject<any>()

  public dataChanged = this._dataChangeSub.asObservable();

  constructor(private http: HttpClient) { }

  sendDataChangeNotification = (active, token) => {
    if(active){
      this.getActiveEmails(token).toPromise()
      .then(
        res => this._dataChangeSub.next(res), 
        err => this._dataChangeSub.next(null)
      );
    } else{
      this.getInactiveEmails(token).toPromise()
      .then(
        res => this._dataChangeSub.next(res), 
        err => this._dataChangeSub.next(null)
      );
    }
  }

  getActiveEmails(token): Observable<any>{
    this.setHttpOptions(token);
    return this.http.get(environment.baseApiUrl + '/emails/active', this.httpOptions);
  }

  getInactiveEmails(token): Observable<any>{
    this.setHttpOptions(token);
    return this.http.get(environment.baseApiUrl + '/emails/inactive', this.httpOptions);
  }

  deleteEmails(emailIds, token): Observable<any>{
    this.setHttpOptions(token);
    this.httpOptions.body = emailIds;
    return this.http.delete(environment.baseApiUrl + '/emails', this.httpOptions);
  }

  activateEmail(email, token): Observable<any>{
    this.setHttpOptions(token);
    this.httpOptions.responseType = 'text';

    return this.http.post(
      environment.baseApiUrl + '/emails/activate', email, this.httpOptions)
  }

  addEmail(email): Observable<any>{
    return this.http.post(
      environment.baseApiUrl + '/emails', 
      email, 
      {headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'text'});
  }

  updateEmail(email, token): Observable<any>{
    this.setHttpOptions(token);
    this.httpOptions.responseType = 'text';

    return this.http.put(
      environment.baseApiUrl + '/emails/' + email.id, email, this.httpOptions);
  }
  
  setHttpOptions = (token) => this.httpOptions = {
    headers: new HttpHeaders({
      Authorization: token,
      'Content-Type': 'application/json'
    }),
  }
}
