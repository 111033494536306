import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Clipboard } from "@angular/cdk/clipboard"
import { MatSnackBar } from '@angular/material/snack-bar';
import { Email } from '../models/Email';
import { Subscription } from 'rxjs';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-inactive-emails',
  templateUrl: './inactive-emails.component.html',
  styleUrls: ['./inactive-emails.component.css']
})
export class InactiveEmailsComponent implements AfterViewInit, OnInit, OnDestroy {

  dataSource;
  isSmallScreen: boolean;
  breakpointSubscription: Subscription;
  displayedColumns: string[] = ['select', 'id', 'firstName', 'lastName', 'emailAddress', 'deleted'];
  displayedColumnsSmallScreen: string[] = ['select', 'firstName', 'emailAddress', 'deleted'];
  selection = new SelectionModel<Email>(true, []);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  
  constructor(
    private _route: ActivatedRoute, 
    private _apiService: ApiService,
    private _authService: AuthService,
    private _clipboard: Clipboard,
    private _snackBar: MatSnackBar,
    private _breakpointObserver: BreakpointObserver) {
    this.dataSource = new MatTableDataSource<Email>(this._route.snapshot.data['data']);   
  }

  ngOnInit(): void {
    this._apiService.dataChanged
      .subscribe(res => this.dataSource = new MatTableDataSource<Email>(res));

    this.breakpointSubscription = this._breakpointObserver
      .observe(['(max-width: 450px)'])
      .subscribe((state: BreakpointState) => this.isSmallScreen = state.matches);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 2000,
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: Email): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  
  activateEmail(email){
    let token = this._authService.getToken;

    this._apiService.activateEmail(email, this._authService.getToken)
      .toPromise()
      .then(x => {
        this.openSnackBar('Email is successfully reactivated!');
        this._apiService.sendDataChangeNotification(false, token);
    });
  }

  copyEmailsToClipboard(){
    var emails = this.dataSource.filteredData.filter(x => !x.deleted);
    const pending = this._clipboard.beginCopy(emails.map(x => x.emailAddress).join(';'));
    let remainingAttempts = 3;
    const attempt = () => {
      const result = pending.copy();
      if (!result && --remainingAttempts) {
        setTimeout(attempt);
      } else {
        this.openSnackBar(`Successfully copied ${emails.length} emails!`);
        pending.destroy();
      }
    };
    attempt();
  }

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
  }
}

