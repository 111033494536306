import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../services/auth.guard';
import { ActiveEmailsComponent } from './active-emails/active-emails.component';
import { AdminComponent } from './admin.component';
import { EditComponent } from './edit/edit.component';
import { InactiveEmailsComponent } from './inactive-emails/inactive-emails.component';
import { ActiveEmailsResolverService } from './services/resolvers/active-emails-resolver.service';
import { InactiveEmailsResolverService } from './services/resolvers/inactive-emails-resolver.service';

const routes: Routes = [
  { 
    path: 'admin', 
    component: AdminComponent,
    canActivate: [AuthGuard],
    children:[
      {
        path: '',
        component: ActiveEmailsComponent,
        resolve: { data: ActiveEmailsResolverService }
      },
      {
        path: 'active-emails',
        component: ActiveEmailsComponent,
        resolve: { data: ActiveEmailsResolverService }
      },
      {
        path: 'inactive-emails',
        component: InactiveEmailsComponent,
        resolve: { data: InactiveEmailsResolverService }
      },
      {
        path: 'edit',
        component: EditComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
