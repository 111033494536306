import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Email } from 'src/app/admin/models/Email';

@Component({
  selector: 'app-register-email',
  templateUrl: './register-email.component.html',
  styleUrls: ['./register-email.component.css']
})
export class RegisterEmailComponent {
  email: Email;
  showAllErrors = false;

  constructor(
    public dialogRef: MatDialogRef<RegisterEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Email) {
    this.email = data;
    this.loadForm(document, "script", "aweber-wjs-cmn20pb6g");
  }

  submit(valid){
    if(valid) this.dialogRef.close(this.data);
    
    this.showAllErrors = true;
    return;
  }

  close(){
    this.dialogRef.close();
  }
  
  loadForm(d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    js = d.createElement(s); js.id = id;
    js.src = "//forms.aweber.com/form/93/1109032493.js";
    fjs.parentNode.insertBefore(js, fjs);
    }
}
