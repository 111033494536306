import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { Email } from '../admin/models/Email';
import { ApiService } from '../services/api.service';
import { RegisterEmailComponent } from './register-email/register-email.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit, OnDestroy{
  email: Email;
  isSmallScreen: boolean;
  breakpointSubscription: Subscription;

  constructor(
    public dialog: MatDialog, 
    private _apiService: ApiService, 
    private _snackBar: MatSnackBar,
    private _breakpointObserver: BreakpointObserver) { }

  ngOnInit(): void {
    this.breakpointSubscription = this._breakpointObserver
      .observe(['(max-width: 450px)'])
      .subscribe((state: BreakpointState) => this.isSmallScreen = state.matches);
  }

  openDialog(): void {
    this.email = this.getEmptyEmail();
    const dialogRef = this.dialog.open(RegisterEmailComponent);
  }

  addEmail(email): void {
    this._apiService.addEmail(email)
      .toPromise().then(x => {
        this.openSnackBar('Thanks for subscribing to our email list!');
      }, err => {
        console.log(err);
        this.openSnackBar('Sorry something went wrong, please try again later or contact us by email.')
      });
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'OK', {
      duration: 5000,
    });
  }

  getEmptyEmail(){
    let email: Email = {
      deleted: false,
      firstName: '',
      lastName: '',
      id: 0,
      emailAddress: ''
    }

    return email;
  }

  ngOnDestroy(): void {
    this.breakpointSubscription.unsubscribe();
  }
}
